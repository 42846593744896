import { useMemo, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
	CircularProgress,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import SimpleServicesSetupSelect from "../../../components/simpleServicesSetupSelect";
import VpcSetupForm from "../../../components/vpcSetupForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { submitAifNetwork } from "../../../store/aifCreationForm";
import AwsAccountSelectForm from "../../../components/awsAccountSelectForm";
import ErrorToast from "../../../components/errorToast";
import { useNavigate } from "react-router-dom";
import { useOcnHooks } from "../../../api";

export default function OcnCreateForm() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		step: activeStep,
		accountSetup,
		vpcSetup,
		servicesSetup,
		error,
	} = useAppSelector((state) => state.ocnCreationForm);
	const { list: accountList } = useAppSelector((state) => state.awsAccounts);
	const [loading, setLoading] = useState(false);
	const { useListNetworks } = useOcnHooks();
	const { data: networks } = useListNetworks();
	const undestroyedNetworks = useMemo(
		() =>
			networks?.data?.filter((network) => network.state !== "Destroyed") ?? [],
		[networks?.data],
	);

	// TODO: undo this when OCNNetwork supports taking a ref
	// this is a temp hack because OCN Network is taking the external_id and role_arn to make new ones.
	// Once OCN Network is updated, it'll just take accountSetup.id instead.
	const accountDetails = accountList.find(
		(account) => account.id === accountSetup?.id,
	);
	const networkReady =
		accountSetup && vpcSetup && servicesSetup && accountDetails;
	const steps = [
		{
			label: "Connect AWS Account",
			component: <AwsAccountSelectForm />,
		},
		{
			label: "VPC Setup",
			component: <VpcSetupForm />,
		},
		{
			label: "Services Setup",
			component: <SimpleServicesSetupSelect />,
		},
		{
			label: "Done!",
			component: (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography variant="body1">
						That's everything we need. Let's go!
					</Typography>
				</Paper>
			),
		},
	];

	const sendNetwork = () => {
		if (networkReady) {
			setLoading(true);
			dispatch(
				submitAifNetwork({
					// TODO: undo this when OCNNetwork supports taking a ref
					account: accountDetails,
					...vpcSetup,
					...servicesSetup,
				}),
			).finally(() => {
				setLoading(false);
			});
		}
	};

	const goToInventory = () => {
		navigate("/");
	};

	return (
		<>
			<ErrorToast showErrorToast={!!error} errorMessage={error}></ErrorToast>
			<Dialog
				open
				fullWidth
				maxWidth="md"
				scroll="paper"
				slotProps={{
					backdrop: {
						style: { backgroundColor: "transparent" },
					},
				}}
				PaperProps={{
					style: { pointerEvents: "auto" },
				}}
				onClose={(_, reason) => {
					// Prevent closing the dialog when clicking the backdrop or pressing the Escape key
					if (reason === "backdropClick" || reason === "escapeKeyDown") {
						return;
					}
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						p: 4,
					}}
				>
					<DialogTitle sx={{ m: 0, p: 0 }}>
						Create New AI Fabric Connection
					</DialogTitle>
					{undestroyedNetworks?.length > 0 && (
						<Button variant="contained" type="button" onClick={goToInventory}>
							Go to Inventory
						</Button>
					)}
				</Box>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText>
							Let's collect some information to get started.
						</DialogContentText>
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((step) => (
								<Step key={step.label}>
									<StepLabel>{step.label}</StepLabel>
									<StepContent>{step.component}</StepContent>
								</Step>
							))}
						</Stepper>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						disabled={!networkReady || loading}
						onClick={sendNetwork}
						startIcon={loading ? <CircularProgress size={20} /> : null}
					>
						{loading ? "Submitting ..." : "Submit"}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
